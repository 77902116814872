<template>
  <div>
    <ValidationObserver ref="observer">
      <b-form
        slot-scope="{ validate }"
        @submit.prevent="validate().then(onSubmit)"
      >
        <b-sidebar bg-variant="white" v-model="is" backdrop shadow>
          <template #header>
            <div
              class="d-flex justify-content-between align-items-center px-1 py-50 bg-light"
            >
              <h5 class="m-0">
                <strong>إضافة مستخدم جديد</strong>
              </h5>
              <b-button
                @click="is = false"
                size="sm"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
              >
                <unicon name="times" width="16" height="16" />
              </b-button>
            </div>
          </template>
          <b-col cols="12">
            <BTextInputWithValidation
              rules="required"
              label="الاسم الكامل"
              name="الاسم الكامل"
              v-model="userForm.fullName"
              placeholder="أدخل الاسم والكنية"
            />
            <BTextInputWithValidation
              rules="required"
              label="اسم المستخدم"
              name="اسم المستخدم"
              v-model="userForm.username"
              placeholder="أدخل اسم المستخدم"
            />
            <BTextInputWithValidation
              rules="required|min:4"
              label="كلمة السر"
              name="كلمة السر"
              type="password"
              v-model="userForm.password"
              placeholder="أدخل كلمة السر"
            />
            <legend class="bv-no-focus-ring col-form-label pt-0">الصلاحية</legend>
            <validation-provider
              #default="{ errors }"
              name="الصلاحية"
              rules="required"
              label="الصلاحية"
            >
              <v-select
                id="role"
                v-model="userForm.role"
                label="name"
                :dir="$store.state.appConfig.isRTL ? 'ltr' : 'rtl'"
                :options="rolesList"
                :reduce="(item) => item.name"
              ></v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <template #footer>
            <div class="d-flex p-1 border-top">
              <b-button @click="is = false" variant="outline-primary" class="ml-auto mr-1"
                >إلغاء</b-button
              >
              <b-button variant="primary" type="submit">تم</b-button>
            </div>
          </template>
        </b-sidebar>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BTextInputWithValidation from "@core/components/inputs/BTextInputWithValidation";
import vSelect from "vue-select";
import { BSidebar, BButton, BCol, BForm } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BSidebar,
    BButton,
    BCol,
    BTextInputWithValidation,
    ValidationObserver,
    ValidationProvider,
    BForm,
    vSelect,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["userForm", "rolesList"]),
    is: {
      get() {
        return this.$store.getters["isCreateAccountModal"];
      },
      set(is) {
        this.$store.dispatch("setIsCreateAccountModal", is);
      },
    },
  },
  watch: {
    subject(val) {
      console.log(val);
    },
  },
  methods: {
    ...mapActions(["setIsCreateAccountModal", "addUser"]),
    onSubmit() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          this.addUser({
            id: this.userForm.id,
            fullName: this.userForm.fullName,
            username: this.userForm.username,
            password: this.userForm.password,
            role: this.userForm.role,
          }).then(() => {
             Object.assign(this.userForm, {
              id: 0,
              fullName: "",
              username: "",
              password: "",
              role: 0,
            });
            requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
            this.is = false;
          });
        }
      });
    },
  },
};
</script>
